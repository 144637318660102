import React from 'react'
import NavBar from '../components/common/NavBar'
import JobIntro from '../components/about/career/JobIntro'
import JobContent from '../components/about/career/JobContent'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

const Job = ({ data }) => {
  const { job } = data
  const technologiesNodes = data.job_technologies.edges
  const benefitsNodes = data.benefits.edges

  return (
    <>
      <Helmet
        title={`${job.title} | Career | Zaven Wrocław`}
        meta={[{ name: 'description', content: job.description }]}
      />
      <NavBar />
      <JobIntro />
      <JobContent
        job={job}
        technologiesNodes={technologiesNodes}
        benefitsNodes={benefitsNodes}
      />
    </>
  )
}

export default Job

export const query = graphql`
  query JobQuery($slug: String!, $job_technologies: String!) {
    job: jobsYaml(fields: { slug: { eq: $slug } }) {
      ...Job
    }
    job_technologies: allTechnologiesJson(
      filter: { short: { regex: $job_technologies } }
    ) {
      ...Technologies
    }
    benefits: allCareerBenefitsJson {
      ...Benefits
    }
  }
`
