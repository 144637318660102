import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChessKnight } from '@fortawesome/pro-solid-svg-icons'

const JobIntro = () => (
  <section className="job-intro" style={{ overflow: 'hidden' }}>
    <div className="container">
      <div className="row">
        <div className="col-md-8">
          <div
            className="job-intro-icon"
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          >
            <FontAwesomeIcon
              icon={faChessKnight}
              style={{
                opacity: 0.1,
                fontSize: '8em',
                position: 'absolute',
                bottom: '-3rem',
                right: '-8rem',
              }}
            />
          </div>
          <p className="text-sm text-muted" style={{ position: 'relative' }}>
            From a group of friends making small projects to an international
            company, Zaven developed quickly into an expert in providing web and
            mobile solutions for businesses and startups. If you like to see the
            bigger picture of your job, get tangible effects and work among
            great people, <strong>this is the place for you</strong>.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default JobIntro
